<template>
  <Layout></Layout>
  <div class="container">
    <div class="row">
      <div class="col-md-12 app-title">
        <h1>Mint</h1>
      </div>
      <div class="col-12 text-center" v-if="totalSupply > 0">
        <p>Mint traits to dress up your $RAT. - TRAIT ONLY!!</p>
        <p>{{ traitSupply }} / {{ totalSupply }} claimed.</p>
        <div class="input-group mb-3 center-block" style="display: table">
          <button @click="setMintTraitAmount(1)" v-bind:class="'btn ' + (amountTraitMint === 1?'btn-danger':'btn-primary')">1</button>
          <button @click="setMintTraitAmount(2)" v-bind:class="'btn ' + (amountTraitMint === 2?'btn-danger':'btn-primary')">2</button>
          <button @click="setMintTraitAmount(3)" v-bind:class="'btn ' + (amountTraitMint === 3?'btn-danger':'btn-primary')">3</button>
          <button @click="setMintTraitAmount(4)" v-bind:class="'btn ' + (amountTraitMint === 4?'btn-danger':'btn-primary')">4</button>
          <button @click="setMintTraitAmount(5)" v-bind:class="'btn ' + (amountTraitMint === 5?'btn-danger':'btn-primary')">5</button>
          <button @click="setMintTraitAmount(6)" v-bind:class="'btn ' + (amountTraitMint === 6?'btn-danger':'btn-primary')">6</button>
          <button @click="setMintTraitAmount(7)" v-bind:class="'btn ' + (amountTraitMint === 7?'btn-danger':'btn-primary')">7</button>
          <button @click="setMintTraitAmount(8)" v-bind:class="'btn ' + (amountTraitMint === 8?'btn-danger':'btn-primary')">8</button>
          <button @click="setMintTraitAmount(9)" v-bind:class="'btn ' + (amountTraitMint === 9?'btn-danger':'btn-primary')">9</button>
          <button @click="setMintTraitAmount(10)" v-bind:class="'btn ' + (amountTraitMint === 10?'btn-danger':'btn-primary')">10</button>
        </div>
        <button v-bind:class="'btn btn-primary ' + (!this.enabled?'disabled':'')" @click="mintEquipment">Mint. [0.005 BCH]</button>
      </div>
    </div>
  </div>
</template>

<script>
import {ethers} from "ethers";
import remint from "../../../../artifacts/contracts/TraitMint.sol/TraitMint.json";
import nftInfo from "../../../../artifacts/contracts/CryptoRATOriginals.sol/CryptoRATOriginals.json";
import {nftCA, traitMint} from "../../../config";
import Layout from "@/components/Layout";
export default {
  components: {Layout},
  data() {
    return {
      traits: [],
      amountTraitMint: 1,
      traitSupply: 0,
      enabled: false,
      totalSupply: 0,
    }
  },
  watch: {
    '$store.state.provider': function() {
      this.loadSupply();
    }
  },
  mounted() {
    fetch(this.api_url + '/api/traits').then(function(response){
      return response.json();
    }).then(function(traits) {
      this.app.traits = traits;
    }.bind({app: this}));
    this.loadSupply();
  },
  methods: {
    loadSupply: function() {
      if(this.$store.state.provider) {
        let nft = new ethers.Contract(nftCA, nftInfo.abi, this.$store.state.provider);
        nft.minters(traitMint).then(result => {
          this.enabled = result;
          let minter = new ethers.Contract(traitMint, remint.abi, this.$store.state.provider);
          minter.supply().then((amount) => {
            this.totalSupply = (amount - 1);
          });
          minter.minted().then((amount) => {
            this.traitSupply = amount;
          });
        });
      }
    },
    setMintTraitAmount: function(amount) {
      this.amountTraitMint = amount;
    },
    mintEquipment: async function() {
      let minter = new ethers.Contract(traitMint, remint.abi, this.$store.state.provider.getSigner());
      await (await minter.mint(this.amountTraitMint, {value: ethers.utils.parseEther((0.005 * this.amountTraitMint).toString())})).wait();
      window.location.reload();
    },
  }
}
</script>